.page-404 {
    &__wrap {
        display: flex;
        flex-direction: column;
        align-items: center;
    }
    &__int {
        font-size: calcFluid(100, 200);
        line-height: 1;
        font-weight: 900;
        letter-spacing: 10px;
        display: block;
        margin-bottom: calcFluid(10, 20);
        color: #333;
    }
    &__title {
        font-size: calcFluid(22, 36);
        line-height: calcFluid(28, 42);
        font-weight: 700;
        display: block;
        margin-bottom: calcFluid(7, 15);
        color: #333;
    }
    &__text {
        font-size: calcFluid(15, 18);
        line-height: calcFluid(20, 24);
        display: block;
        margin-bottom: calcFluid(20, 35);
        color: #333;
        text-align: center;
    }
    &__button {
        width: 290px;
        height: 50px;
    }
}