.map {
    width: 100%;
	height: rem(480);
	border: 3px solid #D9D9D9;
}

.contacts {
	display: flex;
	align-items: flex-start;
	margin-bottom: rem(35);
}

.contact {
	display: flex;
	margin-right: rem(140);

	svg {
		width: rem(33);
		min-width: rem(33);
		height: rem(33);
		min-height: rem(33);
		
		margin-right: rem(14);
	}

	&__value {
		a {
			display: flex;
			align-items: center;
			height: 100%;
			transition: all 0.3s;
		}
	}

	&__text {
		@include font-p;
		color: #000000;
		height: 100%;

		display: flex;
		align-items: center;
		height: 100%;
	}
}

@media (max-width: 1199px){
	.contacts{
		justify-content: space-between;
	}
	.contact{
		margin: 0;
	}
}
@media (max-width: 767px){
	.contact{
		&:not(:last-child){
			margin-bottom: mobRem(15);
		}
	}
	.contacts{
		display: block;
	}
}