.header {
    border-bottom: 1px solid #D8D8D8;
    position: fixed;
    width: 100%;
    top: 0;
    left: 0;
    z-index: 99999999;
    background-color: #FFFFFF;

    &__wrapper {
        height: rem(90);
        display: flex;
        justify-content: space-between;
        align-items: center;
    }

    &__city {
        font-size: rem(21);
        line-height: 130%;
        border-left: 1px solid #D8D8D8;
        border-right: 1px solid #D8D8D8;
        // padding: 0 rem(53);
        width: rem(220);
        height: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        cursor: pointer;
        position: relative;
        user-select: none;

        &-inner {
            
            &:before {
                content: '';
                background-image: url(../images/li.svg);
                background-position: center center;
                background-repeat: no-repeat;
                width: rem(78);
                height: rem(46);
                position: absolute;
                top: 50%;
                left: 50%;
                z-index: -1;
                opacity: 0;
                transition: all 0.3s;
                transform: translate(-50%, -50%);
            }
    
            &:hover {
                &:before {
                    opacity: 1;
                }
            }
        }

        svg {
            margin-left: rem(28);
            width: rem(15);
            height: rem(15);
            fill: #000000;
            transition: all 0.3s;

        }

        ul {
            position: absolute;
            top: 100%;
            left: 0;
            width: 100%;
            height: max-content;
            background-color: #FFFFFF;
            z-index: 10;
            opacity: 0;
            visibility: hidden;
            transition: all 0.3s;
            border: 1px solid #D8D8D8;
            padding: rem(15) 0 rem(20);


            li {
                padding: rem(15);
                text-align: center;
                // border-bottom: 1px solid #000000;
                position: relative;

                &:before {
                    content: '';
                    background-image: url(../images/li.svg);
                    background-position: center center;
                    background-repeat: no-repeat;
                    width: rem(78);
                    height: rem(46);
                    position: absolute;
                    top: 50%;
                    left: 50%;
                    z-index: -1;
                    opacity: 0;
                    transition: all 0.3s;
                    transform: translate(-50%, -50%);
                }
        
                &:hover {
                    &:before {
                        opacity: 1;
                    }
                }
            }

            li.current {

                // &:before {
                //     opacity: 1;
                // }
            }
        }
    }

    &__city.active {

        ul {
            opacity: 1;
            visibility: visible;
        }

        svg {
            // fill: #B6F84A;
            transform: rotate(180deg);
        }
    }

    &__phone {
        display: flex;
        align-items: center;

        &-icon {
            width: rem(48);
            height: rem(48);
            display: flex;
            align-items: center;
            justify-content: center;
            background: #B6F84A;
            border-radius: 50%;

            svg {
                width: rem(24);
                height: rem(24);
            }
        }

        &-text {
            display: block;
            margin-left: rem(12);
        }

        &-link {
            font-weight: 700;
            font-size: rem(24);
            line-height: 133%;
            display: block;
            color: #000000;
        }

        &-call {
            cursor: pointer;
            font-size: rem(20);
            line-height: 130%;
            display: block;
            // color: #000000;
            background-color: #000000;
            color: #FFFFFF;
            border-radius: rem(20);
            // margin-top: rem(-5);
            transition: color .4s;
            padding: rem(5);
            display: flex;
            padding-top: rem(4);
            padding-bottom: rem(6);
            justify-content: center;
            transition: all 0.3s;
            font-weight: 700;

            &:hover{
               background-color: #B6F84A;
               color: #000000;
            }
        }
    }
}

.logo {
    display: flex;
    align-items: center;
    width: 20%;

    img {
        display: block;
        flex: 0 0 auto;
        width: 100%;
        max-width: 50%;
        min-width: rem(150);
        height: 100%;
        max-height: 100%;
        min-height: 80%;
        max-width: rem(160);
        object-fit: contain;
    }

    span {
        margin-left: rem(17);
        font-weight: 400;
        font-size: rem(16);
        line-height: 125%;
        color: #696969;
    }
}

.header-menu {
    border-right: 1px solid #D8D8D8;
    height: 100%;
    display: flex;
    align-items: center;
    padding: 0 rem(40);

    > ul {
        height: 100%;
        display: flex;
        align-items: center;

        > li {
            height: 100%;
            display: flex;
            align-items: center;

            > a {
                padding: 4px rem(20);
                font-weight: 700;
                font-size: rem(24);
                line-height: 125%;
                position: relative;

                &:before {
                    content: '';
                    background-image: url(../images/li.svg);
                    background-position: center center;
                    background-repeat: no-repeat;
                    width: rem(78);
                    height: rem(46);
                    position: absolute;
                    top: 50%;
                    left: 50%;
                    z-index: -1;
                    opacity: 0;
                    transition: all 0.3s;
                    transform: translate(-50%, -50%);
                }

                &:hover {
                    &:before {
                        opacity: 1;
                    }
                }
            }
        }
    }

    ul {
        display: flex;
        li {
            a {
                color: #000000;
                transition: color .3s;
                display: flex;
                align-items: center;
                height: 100%;

                svg {
                    width: rem(15);
                    height: rem(15);
                    min-width: rem(15);
                    min-height: rem(15);
                    
                    margin-left: rem(4);

                    fill: #000000;

                    transition: all 0.3s;
                }
            }
            .sub-menu {
                display: none;
                position: absolute;
                z-index: 4;
                padding: rem(15) rem(22) rem(20) rem(30);
                top: 100%;
                left: rem(-10);
                background: #FFFFFF;
                border: 1px solid #D8D8D8;
                // width: fit-content;
                width: max-content;
                min-width: 100%;
                max-width: rem(300);
                transition: all 0.3s;

                li {
                    padding: rem(10) 0;
                    width: 100%;

                    a {
                        @include font-h4;
                        position: relative;
                        width: fit-content;
                        display: block;
                        width: 100%;
                        padding-right: rem(30);

                        &:before {
                            content: '';
                            background-image: url(../images/li.svg);
                            background-position: center center;
                            background-repeat: no-repeat;
                            width: rem(78);
                            height: rem(46);
                            position: absolute;
                            top: 50%;
                            left: 0;
                            z-index: -1;
                            opacity: 0;
                            transition: all 0.3s;
                            transform: translateY(-50%);
                        }

                        &:hover {
                            &:before {
                                opacity: 1;
                            }
                        }
                    }
                }
            }
            &.menu-item-has-children {
                position: relative;
                &:hover {
                    .sub-menu {
                        display: block;
                    }

                    a {
                        svg {
                            transform: rotate(180deg);
                        }
                    }
                }
            }
        }
    }
}



@media(max-width: 1199px) {
    .header {
        &__wrapper {
            height: 50px;
        }
        &__phone{
            &--desktop{
                display: none;
            }
        }
        &__city{
            width: mobRem(120);
            margin: 0 mobRem(15) 0 auto;
            flex: 0 0 auto;
        }
    }

    .header-menu {
        display: none;
    }

    
    .logo {
        width: auto;
        height: 50px;
        span{
            max-width: 105px;
        }
    }
}

@media (max-width: 424px){
    .logo{
        span{
            display: none;
        }
    }
}