/*	Хлебные крошки
---------------------------------------*/
.breadcrumbs {
	margin-bottom: rem(44.5);
	ul {
		display: block;
		font-size: 0;
		li {
			display: inline;
			@include font-18;
			line-height: 1.11;
			color: #9F9F9F;

			a {
				color: #000000;
			}
			&:after {
				content: '—';
				margin: 0 5px;
				@include font-18;
				line-height: 1.11;
				color: #9F9F9F;
			}
			&:last-child {
				&:after {
					display: none;
				}
			}
		}
	}
}


/*	Контентная часть текстовой страницы
---------------------------------------*/

.page-title {
	font-weight: 700;
	font-size: rem(60);
	line-height: 107%;
	letter-spacing: 0.02em;
	color: #000000;

	margin-bottom: rem(30);
}

.content {
	position: relative;

	.text-img {
		display: flex;
		padding-top: 10px;
	}

	/* Текст
	---------------------------------------*/
	&-text {
		h1 {
			@include font-h1;
			line-height: 107%;
			padding-top: rem(50);
			padding-bottom: rem(30);

			&:first-of-type {
				padding-top: 0;
			}
		}

		h2 {
			@include font-h2;
			line-height: 117%;
			padding-top: rem(50);
			padding-bottom: rem(24);
		}

		h3 {
			@include font-h3;
			line-height: 117%;
			padding-top: rem(50);
			padding-bottom: rem(20);
		}

		h4 {
			@include font-h4;
			line-height: 133%;
			padding-top: rem(50);
			padding-bottom: rem(12);
		}

		p {
			@include font-p;
			line-height: 130%;
			padding-bottom: rem(24);
		}

		a {
			text-decoration: underline;
			transition: all 0.3s;
			color: #5C8D0B;

			&:visited{
				color: #5A6016;
			}

			&:hover, &:focus {
				color: #5C8D0B;
				text-decoration: none;
			}
		}

		ol {
			margin-left: rem(60);

			> li {
				@include font-p;
				list-style-type: decimal;
				padding-bottom: rem(24);
				padding-left: rem(25);

				&:last-of-type {
					padding-bottom: 0;
				}
			}
		}

		ul {
			margin-left: rem(67);

			> li {
				@include font-p;
				list-style-type: disc;
				padding-bottom: rem(24);
				padding-left: rem(25);

				&::marker {
					color: #97D72F;
				}

				&:last-of-type {
					padding-bottom: 0;
				}
			}
		}

		img {
			padding: rem(50) 0;
			width: 100% !important;
			height: auto;
			object-fit: cover;
			margin: 0 auto;
		}

		.wp-caption {
			padding: rem(50) 0;
			width: 100% !important;

			img {
				padding: 0;
			}

			.wp-caption-text {
				@include font-18;
				padding-top: rem(14);
				color: #4F576B;
			}
		}
	}
	
	/* Цитата
	---------------------------------------*/
	.quote {
		border-left: 2px solid #97D72F;
		padding: rem(15) rem(45) rem(25);
		margin: rem(50) 0;

		&-text {
			@include font-p;
		}

		&-author {
			padding-top: rem(20);
			@include font-18;
			color: #727272;
		}
	}

	/*  Файлы
	---------------------------------------*/
	.files {
		display: flex;
		flex-wrap: wrap;

		padding: rem(50) 0;

		margin-left: rem(-10);
		margin-right: rem(-10);
	}

	.file-wrapper {
		padding: 0 rem(10);
		margin-bottom: rem(20);
	}

	.file {
		display: flex;
		flex-direction: column;

		padding: rem(24) rem(36) rem(34);
		background-color: #000000;
		transition: .3s;

		height: rem(176);

		position: relative;

		&:after {
            display: block;
            content: url(../images/corner.svg);
            position: absolute;
            width: rem(142);
            height: rem(154);
            top: -1px;
            right: -1px;
            opacity: 0;
            transition: all .3s;
        }

		&:hover {
			&:after {
				opacity: 1
			}
		}

		&__title {
			@include font-h4;
			color: #FFFFFF;
			transition: all 0.3s;
			position: relative;
			z-index: 1;

			overflow: hidden;
			text-overflow: ellipsis;
			display: -webkit-box;
			-webkit-line-clamp: 2;
			-webkit-box-orient: vertical;
		}
	}

	.file-info {
		padding-top: rem(20);
		margin-top: auto;

		display: flex;
		align-items: center;

		svg {
			width: rem(25);
			min-width: rem(25);
			height: rem(34);
			min-height: rem(34);

			margin-right: rem(14);
		}

		&__size {
			@include font-p;

			color: #FFFFFF;
			opacity: 0.7;
			transition: .3s;
		}
	}

	/*  Таблица
	---------------------------------------*/

	h2 + .table {
		margin-top: rem(30);
	}

	.table {

		padding: 0 0 rem(50);

		&__responsive {
			width: 100%;
			min-width: 100% !important;
			overflow: auto;
		}

		table {
			border-collapse: collapse !important;
			border-spacing: 0 !important;
			border: none;

			min-width: 100% !important;
			width: fit-content;

			th {
				background-color: #7BC700;
				@include font-h4;
				color: #FFFFFF;
				padding: rem(25) rem(40) rem(27);
				text-align: left;
				border: 1px solid #7BC700;
				border-left: 1px solid #FFFFFF;
				border-right: none;

				&:first-of-type {
					border-left: 1px solid #7BC700;
				}

				&:last-of-type {
					border-right: 1px solid #7BC700;
				}
			}

			td {
				@include font-p;
				padding: rem(25) rem(40) rem(27);
				border: 1px solid #7BC700;
			}
		}
	}

	/*  Text underline
	---------------------------------------*/
	.title-underline {
		@include font-h2;
		border-bottom: 2px solid #97D72F;
		padding-bottom: rem(6);
	}

	.title-underline + p {
		padding-top: rem(24);
	}

	/*  Галерея
	---------------------------------------*/

	.gallery {
		padding: rem(50) 0;
	}

	.gallery + .gallery {
		margin-top: rem(-20);
		padding-top: 0;
	}

	.gallery__item-wrapper {
		margin-bottom: rem(30);
	}

	.gallery__item  {
		display: flex;
		align-items: center;
		justify-content: center;

		img {
			display: block;
			width: 100%;
			height: 100%;
			max-width: 100%;
			max-height: 100%;
			object-fit: cover;
		}

		&-caption {
			padding-top: rem(14);
			@include font-18;
			color: #4F576B;
		}
	}

	.in-row-6 {
		height: rem(480);
	}

	.in-row-4 {
		height: rem(337);
	}

	/* Шахматка */ 

	.chess-wrapper {

		padding-top: rem(50);
		
		.chess-item {
			display: flex;
			flex-direction: row-reverse;
			align-items: center;
			margin-bottom: rem(100);

			&:last-of-type {
				margin-bottom: rem(50);
			}

			&:nth-child(even) {
				flex-direction: row;

				.chess-text {
					padding-right: 0;
					padding-left: rem(45);
				}
			}
		}

		.chess-img {
			width: 55%;

			img {
				width: 100%;
				height: auto;
				object-fit: cover;
			}
		}

		.chess-text {
			padding-left: 0;
			padding-right: rem(45);
			width: 45%;
			@include font-p;
		}

		.chess-img-caption {
			@include font-18;
			padding-top: rem(14);
			color: #4F576B;
		}
	}

	.chess-wrapper.left {

		.chess-item {
			flex-direction: row;

			&:nth-child(even) {
				flex-direction: row-reverse;

				.chess-text {
					padding-left: 0;
					padding-right: rem(45);
				}
			}
		}

		.chess-text {
			padding-right: 0;
			padding-left: rem(45);
		}
	}
}

/* Pagination */


.pagination-wrap {
	display: flex;
	justify-content: center;
	width: 100%;
	margin-top: rem(45);

	nav {
		display: flex;
		align-items: center;
	}

	a {
		display: flex;
		align-items: center;
		justify-content: center;
	}
}

.pagination-wrap .page-numbers:not(.prev):not(.next) {
	@include font-p;
	color: #000000;
	padding: rem(7) rem(15);
	transition: all 0.3s;
	margin: 0 rem(5);
	display: flex;
	justify-content: center;
	align-items: center;
	border: 2px solid transparent;
	transition: all 0.3s;

	&:hover {
		border: 2px solid #000000;

		@media (max-width: 767px) {
			border: 2px solid #000000;
		}
	}
}

.pagination-wrap {
	.prev, .next {
		padding: 0;
		border: none;
		background: #B6F84A;
		width: max-content;
		transition: all 0.3s;

		&:hover {
			background: #97D72F;
		}

		svg {
			stroke: #000000;
			width: rem(20);
			height: rem(9);
			transition: all 0.3s;
		}
	}

	.dots {
		border: none !important;
	}

	.prev {
		padding: rem(0) rem(11) rem(0) rem(14);
		border-radius: 50% 0 0 50%;
		margin-right: rem(10);
	}

	.next {
		padding: rem(0) rem(14) rem(0) rem(11);
		border-radius: 0 50% 50% 0;
		margin-left: rem(10);
	}
}

.pagination-wrap .page-numbers.current {
	background-color: #000000;
	color: #FFFFFF !important;
}


/* Subpages */ 

.subages__wrapper {
	display: flex;
	flex-wrap: wrap;

	margin-left: rem(-15);
	margin-right: rem(-15);

	padding-top: rem(15);


	.subpage {
		padding: 0 rem(15);
		margin-bottom: rem(40);
		height: auto;

		height: 100%;

		&__inner {
			display: block;
			position: relative;

            &:after {
                content: url(../images/corner.svg);
                position: absolute;
                width: rem(142);
                height: rem(154);
                top: -1px;
                right: -1px;
                opacity: 0;
                transition: all .3s;
            }

            &:hover {
                &:after {
                    opacity: 1;
                }
            }

			p {
				@include font-h4;
				color: #000000;
				margin: 0 auto;
				text-align: center;
				width: 90%;
			}
	
			img {
				width: 100%;
				height: rem(341);
				object-fit: cover;
				margin-bottom: rem(19);
			}
		}
	}
}

@media (max-width: 767px){
	.content{
		.chess-wrapper{
			.chess-item{
				display: block;
				margin-bottom: mobRem(40);
			}
			.chess-img,.chess-text{
				width: 100%;
			}
			.chess-text{
				margin-top: mobRem(16);
				padding: 0 !important;
			}
		}
	}
}