.mobile-menu-open {
    align-items: center;
    justify-content: center;
    cursor: pointer;
    display: none;
    width: 18px;
    height: 18px;
    position: relative;
	$this: &;
	&__button {
		width: 18px;
		height: 2px;
		background-color: $main-color;
		&:after {
			content: "";
			display: block;
			width: 100%;
			left: 0;
			height: 2px;
			background-color: $main-color;
			position: absolute;
			top: 0;
			transition: .3s;
		}
		&:before {
			content: "";
			display: block;
			width: 100%;
			left: 0;
			height: 2px;
			background-color: $main-color;
			position: absolute;
			bottom: 0;
			transition: .3s;
		}
	}
	&.active {
		#{ $this }__button {
			background-color: transparent;
			&:after {
				transform: rotate(-45deg);
				bottom: 0;
				margin: auto;
			}
			&:before {
				transform: rotate(45deg);
				top: 0;
				margin: auto;
			}
		}
	}
}


.mobile-menu {
	display: flex;
	flex-direction: column;
    position: fixed;
    top: 50px;
    right: 0;
    width: 100%;
    height: calc(100% - 50px);
    z-index: 99999999;
    background-color: #fff;
    padding-bottom: 10px;
    overflow-y: auto;
    overflow-x: hidden;
    left: 100%;
    transition: .4s ease-out;
	border-top: 1px solid #D0D0D0;
	&__wrapper {
		padding-top: 15px;
	}
	&.opened{
		left: 0;
	}
	li {
		padding: 0 15px;
		a {
			display: flex;
			align-items: center;
			justify-content: space-between;
			transition: .2s;
			padding: 15px 0;
			font-size: 15px;
			line-height: 19px;
			letter-spacing: 0.03em;
			text-transform: uppercase;
			color: #333;
			&:hover {
				color: $main-color;
				> svg {
					fill: $main-color;
				}
			}
			> svg {
				width: 8px;
				height: 15px;
				fill: #6C7E47;
			}
		}
		&.back {
			background-color: #f2f6f9;
			a {
				display: block;
				font-size: 13px;
				line-height: 17px;
				color: #929FA8;
				> svg {
					margin-right: 20px;
					width: 32px;
					height: 10px;
					fill: #929FA8;
				}
			}
		}
	}
	.sub-menu {
		border-top: 1px solid #D0D0D0;
		position: fixed;
		top: 50px;
		left: 100%;
		overflow: auto;
		width: 100%;
		height: calc(100% - 50px);
		background-color: #fff;
		z-index: 1;
		transition: .3s;
		display: block;
		&.active {
			left: 0;
		}
		li {
			a {
				color: #929FA8;
			}
		}
	}
	&__footer{
		margin-top: auto;
		padding: 15px;
	}
}

@media(max-width: 1199px) {
	.mobile-menu-open {
		display: flex;
	}
}