/* General */

.section-title {
    @include font-h2;
}

section {
    padding: rem(50) 0;

    @media (max-width: 991px) {
        padding: rem(40) 0;
    }
}

/* Banner */ 

.banner {
    padding-top: 0;
    margin-bottom: rem(30);
    position: relative;

    &:after {
        content: '';
        width: 100%;
        height: 100%;
        position: absolute;
        top: 0;
        left: 0;
        background: linear-gradient(90deg, rgba(0, 0, 0, 0.4) 0%, rgba(0, 0, 0, 0) 92.19%);
        display: block;
    }

    video {
        width: 100%;
        height: 100%;
        position: absolute;
        object-fit: cover;
        top: 0;
        left: 0;
    }

    &__wrapper {
        height: rem(674);
        padding-top: rem(170);
    }

    &__title {
        position: relative;
        z-index: 1;
        font-size: rem(64);
        margin-bottom: rem(36);
        color: #FFFFFF;
        font-weight: 700;
        max-width: 58%;

        @media (max-width: 991px) {
            font-size: rem(45);
        }
        @media (max-width: 575px){
            max-width: 100%;
        }
    }

    .btn {
        background-color: #FFFFFF;
        color: #000000;

        &:hover {
            color: #FFFFFF;
            background-color: #000000;
        }
    }
}

/* Slider */ 

.main-slider {
    padding-top: 0;
    padding-bottom: rem(30);

    .swiper-slide {
        min-height: rem(674);
        padding-top: rem(170);
        background-position: center center;
        background-repeat: no-repeat;
        background-size: cover;
        position: relative;

        &:after {
            content: '';
            width: 100%;
            height: 100%;
            position: absolute;
            top: 0;
            left: 0;
            background: linear-gradient(90deg, rgba(0, 0, 0, 0.4) 0%, rgba(0, 0, 0, 0) 92.19%);
            display: block;
        }

        .slide-title {
            position: relative;
            z-index: 1;
            font-size: rem(64);
            margin-bottom: rem(36);
            color: #FFFFFF;
            font-weight: 700;

            @media (max-width: 991px) {
                font-size: rem(45);
            }
        }
    }

    .btn {
        background-color: #FFFFFF;
        color: #000000;

        &:hover {
            color: #FFFFFF;
            background-color: #000000;
        }
    }
}

/* Benefits */ 

.benefits {
    padding: rem(70) 0;

    @media (max-width: 991px) {
        padding: rem(60) 0;
    }

    &__subtitle {
        @include font-p;
        line-height: 130%;
        margin-top: rem(14);
    }

    &__inner {
        margin-top: rem(40);
        display: flex;
        flex-wrap: wrap;
        margin-left: rem(-10);
        margin-right: rem(-10);

        .card__wrapper {
            padding: 0 rem(10);
            margin-bottom: rem(30);

            .card {
                display: flex;
                flex-direction: column;
                align-items: center;

                &-img {
                    width: rem(193);
                    height: rem(210);
                    border: 1px solid #C1C1C1;
                    border-bottom-left-radius: 50%;
                    border-bottom-right-radius: 50%;
                    padding-bottom: rem(46);
                    display: flex;
                    justify-content: center;
                    align-items: flex-end;
                    position: relative;
                    overflow: hidden;

                    &:after {
                        content: url(../images/corner.svg);
                        position: absolute;
                        width: rem(104);
                        height: rem(104);
                        top: -1px;
                        right: -1px;
                        // opacity: 0;
                        transition: all .3s;
                    }

                    img {
                        display: block;
                        max-width: 70%;
                        max-height: 70%;
                        object-fit: contain;
                    }
                }

                &-title {
                    @include font-h4;
                    line-height: 133%;
                    width: 100%;
                    padding: 0 rem(20);
                    padding-top: rem(17);
                    text-align: center;
                    color: #000000;
                }

                &-desc {
                    @include font-20;
                    margin-top: rem(12);
                    text-align: center;
                }
            }
        }
    }
}

/* Services */

.services {
    padding-top: rem(30);
    padding-bottom: 0;

    &__wrapper {
        display: grid;
        gap: rem(20);
        // grid-template-columns: rem(618) rem(130) rem(460) rem(168) rem(448);
        // grid-template-rows: rem(229) rem(218) rem(204);

        grid-template-columns: 1fr 0.5fr 0.5fr 1fr;
        grid-template-rows: rem(350) rem(250);

        @media (max-width: 991px) {
            display: flex;
            flex-wrap: wrap;
            gap: 0;
            margin-left: -7.5px;
            margin-right: -7.5px;

            width: calc(100% + 15px);
        }
    }

    &__item {
        display: block;
        background-position: center center;
        background-repeat: no-repeat;
        background-size: cover;
        padding: rem(30);
        padding-right: rem(150);
        position: relative;

        @media (max-width: 991px) {
            width: calc(33.33% - 15px);
            height: calcFluid(150, 220, 320, 991);
            margin-bottom: rem(25);
            margin-left: 7.5px;
            margin-right: 7.5px;
            padding-right: rem(94);
        }

        @media (max-width: 767px) {
            width: calc(50% - 15px);
        }

        @media (max-width: 576px) {
            width: 100%;
        }

        &:before {
            content: '';
            background-image: url(../images/corner.svg);
            background-position: top right;
            background-repeat: no-repeat;
            background-size: contain;
            position: absolute;
            width: rem(142);
            height: rem(154);
            top: -1px;
            right: -1px;
            opacity: 0;
            transition: all .3s;
            z-index: 1;
        }

        &:after {
            content: '';
            position: absolute;
            left: 0;
            top: 0;
            width: 100%;
            height: 100%;
            background: linear-gradient(180deg, rgba(0, 0, 0, 0.4) 23.96%, rgba(0, 0, 0, 0) 100%);
        }

        &:hover {
            &:before {
                opacity: 1;
            }
        }

        &:nth-child(1) {
            grid-column-start: 1;
            grid-column-end: 3;
            grid-row-start: 1;
            grid-row-end: 2;
        }
        &:nth-child(2) {
            grid-column-start: 3;
            grid-column-end: 5;
            grid-row-start: 1;
            grid-row-end: 2;
        }
        &:nth-child(3) {
            grid-column-start: 1;
            grid-column-end: 2;
            grid-row-start: 2;
            grid-row-end: 3;
        }
        &:nth-child(4) {
            grid-column-start: 2;
            grid-column-end: 4;
            grid-row-start: 2;
            grid-row-end: 3;
        }
        &:nth-child(5) {
            grid-column-start: 4;
            grid-column-end: 5;
            grid-row-start: 2;
            grid-row-end: 3;
        }
        // &:nth-child(6) {
        //     grid-column-start: 2;
        //     grid-column-end: 4;
        //     grid-row-start: 3;
        //     grid-row-end: 4;
        // }
        // &:nth-child(7) {
        //     grid-column-start: 4;
        //     grid-column-end: 6;
        //     grid-row-start: 3;
        //     grid-row-end: 4;
        // }

        &-title {
            color: #FFFFFF;
            @include font-h3;
            line-height: 117%;
            position: relative;
            z-index: 1;
        }
    }
}

/* Callback Form */

.callback {
    position: relative;
    padding: 0;
    margin-top: rem(30);

    .container {
        @media (max-width: 991px) {
            padding: 0
        }
    }

    &:after {
        content: '';
        position: absolute;
        height: 100%;
        width: 50%;
        top: 0;
        left: 0;
        background-color: #B6F84A;
        display: block;
        z-index: 1;
    }    

    &__wrapper {
        // width: 80%;
        position: relative;
        padding: rem(64) 0;
        padding-right: rem(85);
        background-color: #B6F84A;
        display: flex;
        justify-content: space-between;
        align-items: center;
        border-radius: 0 rem(100) rem(100) 0;
        z-index: 2;

        @media (max-width: 991px) {
            flex-direction: column;
            border-radius: 0;
            padding-right: 0;
        }
    }

    &__title {
        @include font-h2;
        max-width: 50%;

        @media (max-width: 991px) {
            text-align: center;
            margin-bottom: rem(30);
            max-width: 95%;
        }
    }

    &__title.vacancies-callback {
        max-width: 95%;
        display: flex;
        align-items: center;

        span {
            display: inline-block;
            height: rem(3);
            width: rem(42);
            background: #000000;
            opacity: 0.5;
            margin: rem(10) rem(19) 0;
        }

        a {
            color: #000000;
            text-decoration: underline;
            transition: all 0.3s;

            &:hover {
                text-decoration: none;
            }
        }
    }

    .btn {
        padding: 0 rem(44.5);
        
        @media (max-width: 576px) {
            width: 100%;
        }
    }

    &__form {
        max-width: 50%;
        position: relative;

        @media (max-width: 991px) {
            max-width: 95%;
        }

        @media (max-width: 576px) {
            width: 80%;
        } 

        > svg {
            opacity: 0;
            visibility: hidden;
            height: rem(120);
            width: rem(120);
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
        }
        
        form {
            display: flex;
            position: relative;

            @media (max-width: 576px) {
                flex-direction: column;
            }

            p {
                position: absolute;
                bottom: rem(-32);
                color: #696969;
                font-size: rem(18);
                line-height: 111%;

                @media (max-width: 991px) {
                    text-align: center;
                    width: 100%;
                }

                @media (max-width: 576px) {
                    position: static;
                    padding-top: rem(25);
                }

                a {
                    color: #333333;
                    text-decoration: underline;

                    &:hover {
                        text-decoration: none;
                    }
                }
            }

            label {
                height: rem(66);
                border: 1px solid #000000;
                position: relative;
                width: rem(302);
                display: flex;
                align-items: center;
                margin-right: rem(18);

                @media (max-width: 576px) {
                    width: 100%;
                    margin-bottom: rem(25);
                }

                input {
                    width: 100%;
                    height: 100%;
                    background: none;
                    border: none;
                    outline: none;
                    padding-left: rem(30);
                    padding-top: rem(12);
                    @include font-p;
                }

                span {
                    position: absolute;
                    top: 50%;
                    transform: translateY(-50%);
                    left: rem(30);
                    @include font-p;
                    transition: all 0.3s;
                }

                svg {
                    position: absolute;
                    opacity: 0;
                    top: 50%;
                    right: rem(22);
                    transform: translateY(-50%);
                    width: rem(18);
                    height: rem(18);
                }
            }

            label.active {
                span {
                    top: rem(16);
                    font-size: rem(13);
                    line-height: 115%;
                }
            }

            label.valid-error {
                border: 1px solid #E73325;

                span {
                    color: #E73325;
                }
            }

            label.okay {
                svg {
                    opacity: 1;
                }
            }
        }
    }

    &__form.loading {
        form {
            opacity: 0;
            visibility: hidden;
        }

        .lds-default {
            opacity: 1;
            visibility: visible;
        }
    }

    &__form.send-ok {
        form {
            opacity: 0;
            visibility: hidden;
        }

        .lds-default {
            opacity: 0;
            visibility: hidden;
        }

        > svg {
            opacity: 1;
            visibility: visible;
        }
    }
}

.callback.first {
    margin-top: rem(-15);
    transform: translateY(50%);

    @media (max-width: 991px) {
        transform: none;
        margin-top: rem(80);
    }
}

.lds-default {
    display: inline-block;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 80px;
    height: 80px;
    opacity: 0;
    visibility: hidden;
  }
  .lds-default div {
    position: absolute;
    width: 6px;
    height: 6px;
    background: #000000;
    border-radius: 50%;
    animation: lds-default 1.2s linear infinite;
  }
  .lds-default div:nth-child(1) {
    animation-delay: 0s;
    top: 37px;
    left: 66px;
  }
  .lds-default div:nth-child(2) {
    animation-delay: -0.1s;
    top: 22px;
    left: 62px;
  }
  .lds-default div:nth-child(3) {
    animation-delay: -0.2s;
    top: 11px;
    left: 52px;
  }
  .lds-default div:nth-child(4) {
    animation-delay: -0.3s;
    top: 7px;
    left: 37px;
  }
  .lds-default div:nth-child(5) {
    animation-delay: -0.4s;
    top: 11px;
    left: 22px;
  }
  .lds-default div:nth-child(6) {
    animation-delay: -0.5s;
    top: 22px;
    left: 11px;
  }
  .lds-default div:nth-child(7) {
    animation-delay: -0.6s;
    top: 37px;
    left: 7px;
  }
  .lds-default div:nth-child(8) {
    animation-delay: -0.7s;
    top: 52px;
    left: 11px;
  }
  .lds-default div:nth-child(9) {
    animation-delay: -0.8s;
    top: 62px;
    left: 22px;
  }
  .lds-default div:nth-child(10) {
    animation-delay: -0.9s;
    top: 66px;
    left: 37px;
  }
  .lds-default div:nth-child(11) {
    animation-delay: -1s;
    top: 62px;
    left: 52px;
  }
  .lds-default div:nth-child(12) {
    animation-delay: -1.1s;
    top: 52px;
    left: 62px;
  }
  @keyframes lds-default {
    0%, 20%, 80%, 100% {
      transform: scale(1);
    }
    50% {
      transform: scale(1.5);
    }
  }         

/* Work */ 

.work {
    background-image: url(../images/work.svg);
    background-color: #000000;
    background-repeat: no-repeat;
    background-position: center right;
    background-size: contain;

    padding-top: rem(204);
    padding-bottom: rem(100);

    margin-bottom: rem(50);

    @media (max-width: 991px) {
        padding-top: rem(114);
    }

    .section-title {
        padding-bottom: rem(27);
        color: #FFFFFF;
    }

    &__slider {
        user-select: none;

        .swiper {
            overflow: visible;
        }

        .swiper-slide {
            height: rem(256);

            @media (max-width: 576px) {
                height: calcFluid(100, 170, 320, 576);
            }

            img {
                width: 100%;
                height: 100%;
                object-fit: cover;
            }
        }
    }

    &__more {
        color: #FFFFFF;
        @include font-p;
        line-height: 124%;
        display: block;
        margin: 0 auto;
        padding: rem(30) rem(50) 0;
        text-align: center;
    }
}

/* Spec */

.spec {
    .section-title {
        padding-bottom: rem(16);
    }

    &__subtitle {
        @include font-p;
        line-height: 130%;
    }

    &__inner {
        padding-top: rem(28);
        display: flex;
        flex-wrap: wrap;
        margin-left: rem(-15);
        margin-right: rem(-15);

        .card__wrapper {
            padding: 0 rem(15);

            @media (max-width: 991px) {
                margin-bottom: rem(30);
            }
        }

        .card {
            display: block;
            position: relative;

            &:after {
                content: url(../images/corner.svg);
                position: absolute;
                width: rem(142);
                height: rem(154);
                top: -1px;
                right: -1px;
                opacity: 0;
                transition: all .3s;
            }

            &:hover {
                &:after {
                    opacity: 1;
                }
            }

            
            &-img {
                width: 100%;
                height: rem(341);

                @media (max-width: 991px) {
                    height: rem(290);
                }

                img {   
                    width: 100%;
                    height: 100%;
                    object-fit: cover;
                }
            }

            &-title {
                @include font-h4;
                line-height: 133%;
                width: 100%;
                padding: 0 rem(20);
                padding-top: rem(19);
                text-align: center;
                color: #000000;
            }
        }
    }
}

/* Partners */ 

.partners {

    .section-title {
        padding-bottom: rem(42);
    }

    &__inner {
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
        align-items: center;
        padding-left: rem(-10);
        padding-right: rem(-10);

        .partner-item {
            display: flex;
            height: max-content;
            max-width: 20%;
            transition: all 0.3s;
            // margin-right: rem(25);
            margin-bottom: rem(25);
            padding: 0 rem(10);

            @media (max-width: 767px){
                margin: 0;
                max-width: rem(300);
                justify-content: center;
            }

            img {
                max-height: rem(118);
                min-height: rem(30);
                max-width: 100%;
                min-width: rem(80);
                object-fit: contain;
            }

            &:hover {
                opacity: 0.7;
            }
        }

        @media (max-width: 767px){
            display: grid;
            grid-auto-flow: column;
            grid-auto-columns: max-content;
            gap: mobRem(20);
        }
    }
    &__scroll-wrap{
        @media (max-width: 767px){
            display: flex;
            margin: 0 -15px;
            padding: 0 15px;
            overflow: auto;
        }
    }
}

/* SEO */ 

.seo {
    display: flex;
    position: relative;
    height: rem(480);
    
    @media (max-width: 767px) {
        height: auto;
    }
    

    &__wrapper {
        display: flex;
        height: 100%;
        width: 100%;
        position: relative;

        @media (max-width: 767px) {
            flex-direction: column;
        }
    }

    .container {
        display: flex;
        justify-content: flex-end;
    }

    &__img {
        width: 50%;
        padding-right: rem(28);
        position: absolute;
        height: 100%;
        left: 0;
        top: 0;
        overflow: hidden;

        @media (max-width: 767px) {
            position: static;
            margin-bottom: rem(25);
            width: 100%;
            overflow: visible;
            padding-right: 0;
        }

        img {
            width: 100%;
            height: 100%;
            object-fit: cover;
        }
    }

    &__text {
        width: 50%;
        padding-left: rem(28);
        position: relative;

        @media (max-width: 767px) {
            width: 100%;
            max-height: rem(450);
            padding-left: 0;
        }

        &-inner {
            padding-right: rem(26);
            >:last-child{
                padding-bottom: 0;
                margin-bottom: 0;
            }
        }
    }

    // &__text.after {
    //     &:after {
    //         content: '';
    //         width: 2px;
    //         height: 100%;
    //         position: absolute;
    //         right: 1px;
    //         top: 0;
    //         display: block;
    //         background-color: #D9D9D9;
    //         z-index: -1;
    //     }
    // }
}