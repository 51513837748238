.about {
    .section-title {
        padding-bottom: rem(30);
    }

    .more-btn {
        margin-top: rem(45);
        background-color: #FFFFFF;
        color: #000000;
        border: 2px solid #000000;

        &:hover {
            background-color: #000000;
            color: #FFFFFF;
        }
    }
}