.page-news__wrap {
    display: flex;
    flex-wrap: wrap;

    margin-left: rem(-10);
    margin-right: rem(-10);
}

.news-card__wrapper {
    padding: 0 rem(10);
    margin-bottom: rem(20);
    height: auto;
}

.news-card {
    display: flex;
    flex-direction: column;
    background: #FFFFFF;
    border: 1px solid #C1C1C1;
    height: 100%;

    &__img-wrap {
        height: rem(230);
        border-bottom: 1px solid #C1C1C1;
    }

    &__img {
        width: 100%;
        height: 100%;
        display: block;
        object-fit: cover;
    }

    &__info {
        padding: rem(16) rem(24) rem(28);
        color: #000000;
        position: relative;
        flex-grow: 1;

        &:after {
            display: block;
            content: url(../images/corner.svg);
            position: absolute;
            width: rem(142);
            height: rem(154);
            top: 0;
            right: -1px;
            opacity: 0;
            transition: all .3s;
        }
    }

    &__title {
        @include font-h4;
        padding-bottom: rem(12);
        position: relative;
        z-index: 1;
    }

    &__desc {
        @include font-p;
        overflow: hidden;
        text-overflow: ellipsis;
        display: -webkit-box;
        -webkit-line-clamp: 3;
        -webkit-box-orient: vertical;
    }

    &__more {
        position: absolute;
        left: -1px;
        top: 100%;
        @include font-p;
        font-weight: 700;
        background-color: #FFFFFF;
        border: 1px solid #C1C1C1;
        border-top: none;
        width: calc(100% + 2px);
        height: 0;
        overflow: hidden;
        padding: 0 rem(24);
        opacity: 0;
        z-index: 1;
        transition: all 0.3s;

        span {
            line-height: 80%;
            border-bottom: 1px solid #000000;
        }
    }

    &:hover {
        .news-card__info {
            &:after {
                opacity: 1;
            }
        }

        .news-card__more {
            height: rem(55);
            opacity: 1;
        }
    }
}