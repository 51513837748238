.gallery-page {
    &__filters {
        margin-bottom: rem(30);

        span {
            @include font-p;
            border-bottom: 1px dashed #000000;
            cursor: pointer;

            &:first-of-type {
                margin-right: rem(40);
            }
        }

        span.active {
            border-bottom: 1px dashed transparent;
        }
    }

    &__wrapper {
        .photo-gallery {
            display: flex;
            flex-wrap: wrap;

            margin-left: rem(-10);
            margin-right: rem(-10);

            .photo-wrapper {
                padding: 0 rem(10);
                margin-bottom: rem(20);
            }

            .photo {
                display: block;
                height: rem(256);
                position: relative;
            
                &:before {
                    content: '';
                    display: block;
                    background: rgba(0, 0, 0, 0.3);
                    width: 100%;
                    height: 100%;
                    position: absolute;
                    left: 0;
                    top: 0;
                    opacity: 0;
                    transition: all 0.3s;
                }
            
                &:after {
                    display: block;
                    content: url(../images/corners.svg);
                    position: absolute;
                    width: rem(142);
                    height: rem(154);
                    top: -1px;
                    right: -1px;
                    opacity: 0;
                    transition: all .3s;
                }
            
                img {
                    display: block;
                    object-fit: cover;
                    width: 100%;
                    height: 100%;
                }
            
                &:hover {
                    &:before, &:after {
                        opacity: 1;
                    }
                }
            }
        }
        .container{
            @media (max-width: 1199px){
                // width: 100%;
                max-width: 100%;
            }
        }
        .gallery{
            @media (max-width: 1199px){
                // width: 100%;
                max-width: 100%;
            }
        }

        .video-gallery {
            display: flex;
            flex-wrap: wrap;

            margin-left: rem(-10);
            margin-right: rem(-10);

            .video-wrapper {
                padding: 0 rem(10);
                margin-bottom: rem(20);
            }

            .video {
                display: block;
                height: rem(256);
                position: relative;

                &:after {
                    content: '';
                    width: 100%;
                    height: 100%;
                    position: absolute;
                    top: 0;
                    left: 0;
                    background: transparent;
                    display: block;
                    cursor: pointer;
                    z-index: 10;
                }

                &:hover {
                    .play {
                        transform: scale(1.3);

                        @media (max-width: 767px) {
                            transform: none;
                        }
                    }   
                }

                .rll-youtube-player {
                    max-width: unset;
                    height: 100%;
                    padding-bottom: 0;

                    position: relative;

                    > div {
                        &:after {
                            content: '';
                            width: 100%;
                            height: 100%;
                            position: absolute;
                            top: 0;
                            left: 0;
                            background: rgba(0, 0, 0, 0.3);
                            display: block;
                            cursor: pointer;
                        }
                    }
    
    
                    .play {
                        background-image: url(../images/play.svg);
                        background-position: center center;
                        background-size: contain;
                        background-repeat: no-repeat;
                        width: rem(48);
                        height: rem(42);
                        z-index: 1;

                        margin-left: rem(-24);
                        margin-top: rem(-21);

                        transition: all 0.4s;
                    }
    
                    img {
                        display: block;
                        height: rem(256);
                        min-height: 100%;
                        object-fit: cover;
                        max-width: unset;
                        margin: 0;
    
                        &:hover {
                            filter: brightness(1);
    
                            @media (max-width: 767px) {
                                filter: none;
                            }
                        }
                    }
                }
                
                iframe {
                    width: 100%;
                    height: 100%;
                }
            }
        }
    }
}