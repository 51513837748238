.custom-modal {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    height: 100vh;
    overflow: auto;
    z-index: 999999999;
    display: block;
    transition-property: visibility, opacity;
    transition-duration: .6s;
    visibility: hidden;
    background-color: rgba(0,0,0,.5);
    opacity: 0;
    $this:&;
    &.modal-opens,
    &.modal-open {
        visibility: visible;
        opacity: 1;
        .custom-modal__wrapper {
            top: 0;
        }
    }
    &__wrapper {
        width: rem(797);
        max-width: calc(100% - 30px);
        margin: calcFluid(50, 115, 320, 1920) auto 0;
        transition: all 0.6s;
        background-color: #fff;
        position: relative;
        top: -100%;
        // border-radius: rem(30);

        @media (min-width: 1920px) {
            margin: 115px auto 0;
        }
        // opacity: 0;
    }
    &__content {
        display: none;
    }
}

.modal-container {
    width: 100%;
    height: 100%;
    position: relative;
}

.custom-modal-header {
	// border-bottom: 1px solid #CFCFCF;
	&__wrap {
		display: flex;
		align-items: center;
		justify-content: space-between;
		padding: calcFluid(15, 30, 320, 1920) 0;
	}
}

.custom-modal-body {

    .form-wrapper {
        flex-direction: column;
        margin: 0;
        width: 100%;
        height: 100%;
        margin: 0 auto;
        padding: rem(77) rem(78.5);

        @media (max-width: 767px) {
            width: 90%;
        }

        .form-text {
            font-weight: bold;
            text-align: center;
            padding: 0;
            margin-bottom: calcFluid(15, 40);
            // max-width: 90%;

            @media (min-width: 1920px) {
                margin-bottom: 40px;
            }

            @media (max-width: 576px) {
                margin-top: 10px;
            }
        }

        .form {
            width: 100%;
            flex-direction: row;
            padding: 0;

            &-title {
                @include font-h2;
                font-weight: 700;
                padding-bottom: rem(8);
                line-height: 120%;
                text-align: center;
                color: #000000;
            }

            &-subtitle {
                @include font-p;
                padding-bottom: rem(39);
                font-weight: 300;
                line-height: 120%;
                text-align: center;
            }
        }

        .form-inner {
            width: 100%;
            display: flex;
            flex-direction: column;
            align-items: center;

            .lds-default {
                display: inline-block;
                position: absolute;
                top: 50%;
                left: 50%;
                transform: translate(-50%, -50%);
                width: rem(80);
                height: rem(80);
                opacity: 0;
                visibility: 0;
              }
              .lds-default div {
                position: absolute;
                width: rem(6);
                height: rem(6);
                background: #000000;
                border-radius: 50%;
                animation: lds-default 1.2s linear infinite;
              }
              .lds-default div:nth-child(1) {
                animation-delay: 0s;
                top: rem(37);
                left: rem(66);
              }
              .lds-default div:nth-child(2) {
                animation-delay: -0.1s;
                top: rem(22);
                left: rem(62);
              }
              .lds-default div:nth-child(3) {
                animation-delay: -0.2s;
                top: rem(11);
                left: rem(52);
              }
              .lds-default div:nth-child(4) {
                animation-delay: -0.3s;
                top: rem(7);
                left: rem(37);
              }
              .lds-default div:nth-child(5) {
                animation-delay: -0.4s;
                top: rem(11);
                left: rem(22);
              }
              .lds-default div:nth-child(6) {
                animation-delay: -0.5s;
                top: rem(22);
                left: rem(11);
              }
              .lds-default div:nth-child(7) {
                animation-delay: -0.6s;
                top: rem(37);
                left: rem(7);
              }
              .lds-default div:nth-child(8) {
                animation-delay: -0.7s;
                top: rem(52);
                left: rem(11);
              }
              .lds-default div:nth-child(9) {
                animation-delay: -0.8s;
                top: rem(62);
                left: rem(22);
              }
              .lds-default div:nth-child(10) {
                animation-delay: -0.9s;
                top: rem(66);
                left: rem(37);
              }
              .lds-default div:nth-child(11) {
                animation-delay: -1s;
                top: rem(62);
                left: rem(52);
              }
              .lds-default div:nth-child(12) {
                animation-delay: -1.1s;
                top: rem(52);
                left: rem(62);
              }
              @keyframes lds-default {
                0%, 20%, 80%, 100% {
                  transform: scale(1);
                }
                50% {
                  transform: scale(1.5);
                }
              }      

            form {
                padding-top: rem(40);
                display: flex;

                
                @media (max-width: 767px) {
                    flex-wrap: wrap;
                }

                .btn {
                    background-color: #B6F84A;
                    color: #000000;
                    width: 50%;
                    margin-left: rem(9);

                    
                    @media (max-width: 767px) {
                        width: 100%;
                        margin-left: 0;
                        margin-top: 15px;
                    }
                }

                label {
                    display: flex;
                    align-items: center;
                    position: relative;
                    height: rem(66);
                    border: 1px solid #A9A9A9;
                    width: 50%;
                    margin-right: rem(9);

                    span {
                        position: absolute;
                        left: rem(22);
                        top: 50%;
                        transition: all 0.3s;
                        transform: translateY(-50%);
                    }

                    @media (max-width: 767px) {
                        width: 100%;
                    }
                    
                    input {
                        @include font-p;
                        width: 100%;
                        height: 100%;
                        padding-left: rem(22);
                        padding-top: rem(12);
                        border: none;
                        outline: none;
                    }

                    svg {
                        position: absolute;
                        opacity: 0;
                        top: 50%;
                        right: rem(22);
                        transform: translateY(-50%);
                        width: rem(18);
                        height: rem(18);
                    }
                }

                label.active {
                    span {
                        top: rem(16);
                        font-size: rem(13);
                        line-height: 115%;
                    }
                }
    
                label.valid-error {
                    border: 1px solid #E73325;
    
                    span {
                        color: #E73325;
                    }
                }
    
                label.okay {
                    svg {
                        opacity: 1;
                    }
                }
            }

            .success-result {
                display: none;
            }

            p {
                text-align: center;
                padding-top: rem(13);

                a {
                    color: #333333;
                    text-decoration: underline;
                    transition: all 0.3s;

                    &:hover {
                        text-decoration: none;
                        
                        @media (max-width: 767px) {
                            text-decoration: underline;
                        }
                    }
                }
            }
        }

        .form-inner.loading {
            & > * {
                opacity: 0;
                visibility: hidden;
            }
    
            .lds-default {
                display: block;
                opacity: 1;
                visibility: visible;
            }
        }

        .form-inner.send-ok {
            & > * {
                display: none;
            }
        
            .lds-default {
                opacity: 0;
                visibility: hidden;
            }

            .success-result {
                display: flex;
                flex-direction: column;
                align-items: center;
                opacity: 1;
                visibility: visible;

                svg {
                    width: rem(155);
                    height: rem(126);
                    fill: #C3A174;
                    margin-bottom: rem(30);
                }

                .success-title {
                    @include font-h2;
                    color: #272727;
                    margin-bottom: rem(15);
                    text-align: center
                }

                .success-subtitle {
                    @include font-p;
                    text-align: center;
                }

                .pulse-btn {
                    
                    height: rem(65);
                    width: 100%;
                    margin-top: rem(75);
                    color: #FFFFFF;

                    @media (max-width: 991px) {
                        margin-top: rem(50);
                    }
                }
            }
        }

        form {
            justify-content: space-between;
            width: 100%;
        }
    }

    .form-wrapper.send-ok {
        .form-text {
            display: none;
        }

        .success-text {
            font-size: calcFluid(13, 24);

            @media (min-width: 1920px) {
                font-size: 24px;
            }

        }
    }
}

.modal-title {
	font-weight: 700;
    color: #333;
}

.close-modal {
    display: block;
    position: absolute;
    top: rem(34);
    right: rem(34);
    cursor: pointer;
    width: max-content;
    height: max-content;
    margin-left: auto;
    background-color: transparent;
    border: none;
    $this:&;

    &__svg {
        width: rem(24);
        height: rem(24);
        stroke: #000000;
        transition: .3s;
    }

    &:hover {
        svg {
            stroke: #86C51F;

            @media (max-width: 767px) {
                stroke: #000000;
            }
        }
    }
}
