.projects {
    &__wrapper {
        display: flex;
        flex-wrap: wrap;
    }

    .project-item {
        width: 100%;
        padding: rem(80) 0;
        background-image: url(../images/work.svg);
        background-color: #000000;
        background-repeat: no-repeat;
        background-position: top rem(-140) right;
        background-size: cover;

        &:nth-child(even) {
            padding: rem(110) 0;
            background-color: #FFFFFF;
            background-image: none;

            .project-card__info {
                background-color: #1A1A1A;
                color: #FFFFFF;
            }

            .project-card__date {
                color: #FFFFFF;
                opacity: 0.3;
            }
        }

        .container {
            display: flex;
        }
    }

    .project-card__wrapper {

        .project-card {
            display: block;
            position: relative;
            &__img-wrap {
                width: 66.4%;
                transition: all 0.4s;

                img {
                    display: block;
                    width: 100%;
                    height: rem(560);
                    object-fit: cover;
                }
            }

            &__info {
                position: absolute;
                top: rem(-15);
                right: rem(-60);
                background-color: #F2F2F2;
                padding: rem(30) rem(36) rem(40);
                width: rem(427);
                color: #000000;
                overflow: hidden;

                &:after {
                    display: block;
                    // content: url(../images/acorner.svg);
                    content: '';
                    background-image: url(../images/acorner.svg);
                    background-repeat: no-repeat;
                    background-size: contain;
                    background-position: center center;
                    position: absolute;
                    width: rem(149);
                    height: rem(149);
                    top: -1px;
                    right: -1px;
                    opacity: 0;
                    transition: all .3s;

                    @media (max-width: 767px) {
                        display: none;
                    }
                }
            }

            &__title {
                @include font-h4;
                padding-bottom: rem(14);
            }

            &__desc {
                @include font-p;
            }

            &__date {
                position: absolute;
                right: 0;
                bottom: 0;
                text-align: right;
                letter-spacing: 0.02em;
                color: #000000;
                opacity: 0.3;
                @include font-h1;
                line-height: 75%;
            }

            &:hover {
                .project-card__img-wrap {
                    transform: scale(1.023);
                }

                .project-card__info {
                    &:after {
                        opacity: 1;
                    }
                }
            }
        }


        &:nth-child(even) {
            .project-card {
                &__img-wrap {
                    margin-left: auto;
                }

                &__info {
                    top: auto;
                    bottom: rem(-15);
                    right: auto;
                    left: rem(-60);
                }
            }
        }
    }
}

@media (max-width: 1199px){
    .projects{
        .project-item{
            &:nth-child(even){
                padding: mobRem(55) 0 mobRem(25);
            }
        }
    }
}
@media (max-width: 767px){
    .projects{
        .project-item{
            .container{
                display: block;
            }
        }
        .project-card__wrapper{
            &:not(:last-child){
                margin-bottom: mobRem(35);
            }
            .project-card{
                display: flex;
                flex-direction: column;
                &__img-wrap{
                    width: 100%;
                    order: 1;
                    img{
                        height: mobRem(270);
                    }
                }
                &__info{
                    position: static;
                    width: auto;
                    padding: mobRem(20) mobRem(16) mobRem(14);
                }
                &__title{
                    padding-bottom: mobRem(5);
                }
            }
        }
    }
}