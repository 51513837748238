@import 'includes/variables';
@import 'includes/mixins';

@import './general';
@import './post';
@import './fonts';




// styles

@import 'components/common/header';
@import 'components/common/mobile-menu';
@import 'components/common/footer';
@import 'components/common/modal';



@import 'components/pages/index';
@import 'components/pages/page-404';
@import 'components/pages/page-contacts';
@import 'components/pages/page-search';
@import 'components/pages/reviews-page';
@import 'components/pages/archieve';
@import 'components/pages/cert-page';
@import 'components/pages/gallery-page';
@import 'components/pages/vacancies-page';
@import 'components/pages/about-page';
@import 'components/pages/projects-page';




