.reviews {  
    &__wrapper {
        display: flex;
        flex-wrap: wrap;
        margin-left: rem(-10);
        margin-right: rem(-10);
    }

    .review__wrapper {
        padding: 0 rem(10);
        margin-bottom: rem(20);
    }

    .review {
        display: flex;
        flex-direction: column;
        height: rem(373);
        padding: rem(30);
        background: #FFFFFF;
        border: 1px solid #C1C1C1;

        &__date {
            margin-bottom: rem(15);
            @include font-18;
            line-height: 20px;
            color: #636363;
        }

        &__name {
            margin-bottom: rem(12);
            @include font-h4;
        }

        &__text {
            @include font-p;
            margin-bottom: rem(14);
            line-height: 130%;

            overflow: hidden;
            text-overflow: ellipsis;
            display: -webkit-box;
            -webkit-line-clamp: 7;
            -webkit-box-orient: vertical;
        }

        &__more {
            width: max-content;
            @include font-p;
            font-weight: 700;
            line-height: 100%;
            color: #000000;
            border-bottom: 1px dashed #000000;
            cursor: pointer;
            transition: all 0.3s;

            &:hover {
                border-bottom: 1px dashed transparent;
            }
        }
    }

    &__modal {
        position: fixed;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        height: 100vh;
        overflow: auto;
        z-index: 99999999;
        display: block;
        transition: all 0.6s;
        visibility: hidden;

        &-wrapper {
            background-color: #FFFFFF;
            margin: 0 auto;
            margin-top: rem(170);
            width: rem(790);
            padding: rem(30) rem(50) rem(60);
            position: relative;
            top: -100%;
            transition: all 0.6s;
        }

        &-close {
            position: absolute;
            top: rem(30);
            right: rem(30);
            width: max-content;
            height: max-content;
            cursor: pointer;
            
            svg {
                transition: all 0.3s;
                stroke: #000000;
                width: rem(20);
                min-width: rem(20);
                height: rem(20);
                min-height: rem(20);
            }

            &:hover {
                svg {
                    stroke: #86C51F;
                }
            }
        }

        &-inner {
            h2 {
                @include font-h2;
                padding-bottom: rem(24);
            }

            .review__text {
                display: block;
                overflow: visible;
                text-overflow: unset;
            }
        }
    }

    &__modal.opened {
        visibility: visible;
        background-color: rgba(0,0,0,.5);

        .reviews__modal-wrapper {
            top: 0;
        }
    }
}