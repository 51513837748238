.certs {
    &__container{
        display: grid;
        grid-template-columns: repeat(5,1fr);
        gap: rem(20);
    }
}

.cert {
    display: block;
    height: rem(438);
    position: relative;

    &:before {
        content: '';
        display: block;
        background: rgba(0, 0, 0, 0.3);
        width: 100%;
        height: 100%;
        position: absolute;
        left: 0;
        top: 0;
        opacity: 0;
        transition: all 0.3s;
    }

    &:after {
        display: block;
        content: url(../images/corners.svg);
        position: absolute;
        width: rem(142);
        height: rem(154);
        top: -1px;
        right: -1px;
        opacity: 0;
        transition: all .3s;
    }

    img {
        display: block;
        object-fit: cover;
        width: 100%;
        height: 100%;
    }

    &:hover {
        &:before, &:after {
            opacity: 1;
        }
    }
}

@media (max-width: 991px){
    .certs{
        &__container{
            grid-template-columns: repeat(4,1fr);
        }
    }
}
@media (max-width: 767px){
    .certs{
        &__container{
            grid-template-columns: repeat(3,1fr);
        }
    }
}
@media (max-width: 575px){
    .certs{
        &__container{
            grid-template-columns: 1fr;
        }
    }
}