.vacancy {

    &__header {
        height: rem(67);
        padding: 0 rem(29) 0 rem(40);
        display: flex;
        align-items: center;
        justify-content: space-between;
        background: #FFFFFF;
        border: 1px solid #C1C1C1;
        border-bottom: none;
        cursor: pointer;
        transition: all 0.3s;
        user-select: none;

        p {
            @include font-h4;
        }

        svg {
            width: rem(16);
            min-width: rem(16);
            height: rem(9);
            min-height: rem(9);

            margin-left: rem(20);
            fill: #000000;

            transition: all 0.3s;
        }

        &:hover {
            background: #B6F84A;
        }
    }

    &__inner {
        display: flex;
        // border-left: 1px solid #C1C1C1;
        // border-right: 1px solid #C1C1C1;
        // border-top: 1px solid #C1C1C1;
        box-shadow: 0px 0px 0px 1px #C1C1C1 inset;
        overflow: hidden;
        transition: all 0.3s;

        &-left {
            padding: rem(30) rem(40) rem(51);
            width: 57%;
        }

        &-right {
            padding: rem(30) rem(40) rem(51);
            width: 43%;
        }
    }

    &__btn {
        margin-top: rem(45);
        background-color: #B6F84A;
        color: #000000;
    }


    &:last-of-type {
        border-bottom: 1px solid #C1C1C1;
    }
}

.vacancy.active {
    .vacancy__header {
        svg {
            transform: rotate(180deg);
        }
    }

    .vacancy__inner {
        
    }
}

.vacancy-modal {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    height: 100vh;
    overflow: auto;
    z-index: 99999999;
    display: block;
    background-color: rgba(0,0,0,.5);
    transition-property: visibility, opacity;
    transition-duration: .6s;

    &__close {
        position: absolute;
        top: rem(30);
        right: rem(30);
        width: max-content;
        height: max-content;
        cursor: pointer;
        
        svg {
            transition: all 0.3s;
            stroke: #000000;
            width: rem(20);
            min-width: rem(20);
            height: rem(20);
            min-height: rem(20);
        }

        &:hover {
            svg {
                stroke: #86C51F;
            }
        }
    }

    &__wrapper {
        top: -100%;
        position: relative;
        transition: all .6s;
        width: max-content;
        margin: 0 auto;
    }

    &__inner {
        background-color: #FFFFFF;
        margin-top: rem(100);
        width: rem(790);
        padding: rem(70) rem(96) rem(85) rem(76);
        position: relative;
        transition: all .6s;
    }

    &__form {
        &-title {
            @include font-h1;
            padding-bottom: rem(10);
            margin: 0 auto;
            text-align: center;
        }

        &-subtitle {
            @include font-p;
        }

        > svg {
            opacity: 0;
            visibility: hidden;
            height: rem(120);
            width: rem(120);
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
        }

        form {
            margin-top: rem(35);

            > div {
                display: flex;
            }

            label {
                display: block;
                width: 100%;
                position: relative;
                margin-bottom: rem(20);
                background: #FFFFFF;
                border: 1px solid #A9A9A9;
    
                input {
                    display: block;
                    height: rem(66);
                    width: 100%;
                    border: none;
                    outline: none;
                    @include font-p;
                    padding: 0 rem(22);
                    padding-top: rem(12);
                }
    
                span {
                    position: absolute;
                    left: rem(22);
                    top: 50%;
                    transform: translateY(-50%);
                    @include font-p;
                    transition: all 0.3s;
                }
            }

            label.active:not(.label-file) {
                span {
                    top: rem(18);
                    font-size: rem(13);
                }
            }

            label.valid-error {
                border: 1px solid #E73325;

                span {
                    color: #E73325;
                }
            }

            textarea {
                outline: none;
                border: 1px solid #A9A9A9;
                height: rem(174);
                width: 100%;
                background: #FFFFFF;
                resize: none;
                padding: rem(20) rem(22);
                @include font-p;
                color: #000000;
                opacity: 1;
                margin-bottom: rem(20);

                &::placeholder {
                    color: #000000;
                    opacity: 1;
                }
            }
    
            .label-phone, .label-email {
                width: 50%;
            }
    
            .label-phone {
                margin-right: rem(10);
            }
    
            .label-email {
                margin-left: rem(10);
            }

            .label-file {
                width: 50%;
                display: flex;
                align-items: flex-start;
                position: relative;
                margin-right: rem(10);
                height: rem(118);
                padding: rem(23) rem(20) rem(26) rem(25);
                border: 1px dashed #A9A9A9;
                border-bottom-left-radius: rem(40);
                cursor: pointer;
                transition: all 0.3s;

                input {
                    width: 100%;
                    height: 100%;
                    opacity: 0;
                    position: absolute;
                    top: 0;
                    left: 0;
                    cursor: pointer;
                }

                svg {
                    width: rem(25);
                    min-width: rem(25);
                    height: rem(39);
                    min-height: rem(39);

                    margin-right: rem(16.5);
                }

                span {
                    display: block;
                    position: static;
                    transform: none;
                    @include font-p;
                    font-weight: 700;
                    padding-bottom: rem(6);
                    margin-top: rem(-5);
                }

                p {
                    color: #767676;
                    @include font-18;
                    line-height: 111%;
                    overflow: hidden;
                    text-overflow: ellipsis;
                    display: -webkit-box;
                    -webkit-line-clamp: 2;
                    -webkit-box-orient: vertical;
                }

                .remove {
                    display: flex;
                    align-items: center;
                    position: absolute;
                    right: 0;
                    bottom: rem(-32);
                    @include font-18;

                    svg {
                        width: rem(13);
                        height: rem(13);
                        min-width: rem(13);
                        min-height: rem(13);
                        stroke: #000000;
                        margin-right: rem(6);
                    }
                }

                &:hover {
                    background: #DADADA;
                }
            }

            .label-input {
                width: 50%;
                margin-left: rem(10);
                @include font-18;

                .input-submit {
                    width: 100%;
                    background-color: #B6F84A;
                    color: #000000;
                }

                span {
                    display: block;
                    padding-top: rem(12);
                    text-align: center;
                    margin: 0 auto;
                    color: #696969;
                    line-height: 111%;

                    a {
                        color: #333333;
                        text-decoration: underline;
                        transition: all 0.3s;
                        line-height: 111%;

                        &:hover {
                            text-decoration: none;
                        }
                    }
                }
            }
        }
    }

    &__form.loading {
        form {
            opacity: 0;
            visibility: hidden;
        }

        .lds-default {
            opacity: 1;
            visibility: visible;
        }

        .vacancy-modal__form-title {
            opacity: 0;
            visibility: hidden;
        }

        .vacancy-modal__form-subtitle {
            opacity: 0;
            visibility: hidden;
        }
    }

    &__form.send-ok {
        form {
            opacity: 0;
            visibility: hidden;
        }

        .lds-default {
            opacity: 0;
            visibility: hidden;
        }

        > svg {
            opacity: 1;
            visibility: visible;
        }
        .vacancy-modal__form-title{
            opacity: 0;
        }
    }
    &:not(.opened){
        visibility: hidden;
        opacity: 0;
    }
    &.opened {
        .vacancy-modal__wrapper {
            top: 0;
        }
    }
}

@media (max-width: 1199px){
    
}

@media (max-width: 767px){
    .vacancy-modal{
        &__inner{
            width: 100%;
            margin-top: mobRem(30);
            padding: mobRem(28) mobRem(20);
        }
        &__wrapper{
            width: 90%;
        }
        &__form{
            form{
                >div{

                }
            }
        }
        &__file{
            display: block !important;
            .label-input{
                width: auto !important;
                margin-left: 0 !important;
            }
            .label-file{
                width: auto !important;
            }
            margin-left: 0;
        }
    }
    .vacancy{
        &__btn{
            padding: 0 mobRem(12);
        }
    }
    .callback__title.vacancies-callback{
        flex-direction: column;
        span{
            display: none;
        }
    }
}

@media (max-width: 575px){
    .vacancy{
        &__inner{
            flex-direction: column;
            &-left, &-right{
                width: auto;
            }
            &-right{
                padding-top: 0;
            }
            &-left{
                padding-bottom: mobRem(15);
            }
        }
        &__btn{
            margin-top: 0;
        }
    }
}