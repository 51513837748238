.footer {
   
    background-image: url(../images/footer-bg.jpg);
    background-repeat: no-repeat;
    background-size: cover;
    padding-top: rem(137);

    &__inner {
        position: relative;

        &:after {
            content: '';
            position: absolute;
            top: -5%;
            left: 0;
            width: 100%;
            height: 105%;
            background: linear-gradient(180deg, rgba(0, 0, 0, 0) 0%, #000000 40.59%);
        }
    }

    &__upper {
        padding-top: rem(50);
        padding-bottom: rem(73);
        position: relative;
        z-index: 1;

        .container {
            display: flex;
            justify-content: space-between;

            @media (max-width: 1280px){
                display: grid;
                justify-content: flex-start;
                grid-template-columns: repeat(2, 1fr);
                gap: mobRem(25);
            }
            @media (max-width: 768px){
                grid-template-columns: 1fr;
            }
        }

        &-logo {
            display: flex;
            flex-direction: column;
            width: 14%;
            @media (max-width: 1280px){
                width: auto;
                max-width: 200px;
            }
            img {
                max-height: 100%;
                height: auto;
            }

            span {
                color: #FFFFFF;
                line-height: 125%;
                font-size: rem(16);
                margin-top: rem(17);
            }
        }

        &-menu {
            width: 51%;
            @media (max-width: 1280px){
                width: auto;
            }
            @media (min-width: 769px) and (max-width: 1280px) {
                grid-column: span 2;
            }

            ul {
                    li {
                        a {
                            display: block;
                            color: #FFFFFF;
                            transition: all 0.3s;
                            font-size: rem(21);
                            font-weight: 400;
                            line-height: 130%;
                            margin-bottom: rem(12);

                            &:hover {
                                color: #B6F84A;
                            }
                        }
                    }
            }

           > ul {
                display: flex;
                justify-content: space-between;
                @media (max-width: 1280px){
                    display: grid;
                    grid-template-columns: repeat(4,1fr);
                    gap: mobRem(10) mobRem(15);
                }
                @media (max-width: 768px){
                    grid-template-columns: repeat(2,1fr);
                }
                > li {
                    max-width: 25%;
                    @media (max-width: 1280px){
                        max-width: 100%;
                    }
                    > a {
                        font-weight: 700;
                        margin-bottom: rem(18);
                    }

                    ul {
                        display: block;
                    }
                }

                > li.menu-item-has-children {
                    display: inline-block;
                }

                > li:last-of-type {
                    a {
                        font-weight: 700;
                        margin-bottom: rem(18);
                    }
                }
           }
        }
    }

    &__phone {
        display: flex;
        @media (min-width: 769px) and (max-width: 1280px){
            grid-row: 1;
            grid-column: 2;
            align-self: center;
        }
        &-icon {
            width: rem(48);
            height: rem(48);
            display: flex;
            align-items: center;
            justify-content: center;
            background: #B6F84A;
            border-radius: 50%;

            svg {
                width: rem(24);
                height: rem(24);
            }
        }

        &-text {
            display: block;
            margin-left: rem(12);
        }

        &-link {
            font-weight: 700;
            font-size: rem(24);
            line-height: 133%;
            display: block;
            color: #FFFFFF;
            transition: all 0.3s;

            &:hover {
                color: #B6F84A;
            }
        }

        &-call {
            cursor: pointer;
            font-size: rem(21);
            line-height: 130%;
            display: block;
            color: #FFFFFF;
            margin-top: rem(-5);
            transition: all 0.3s;

            &:hover {
                color: #B6F84A;
            }
        }
    }

    &__wrap {
        position: relative;
        z-index: 1;
        border-top: 1px solid #494949;

        .container {
            display: flex;
            align-items: center;
            height: rem(60);
            @media (max-width: 1280px){
                height: auto;
            }
            @media (max-width: 576px){
                flex-direction: column;
                >:not(:last-child){
                    margin-bottom: mobRem(5);
                }
            }
        }
        @media (max-width: 1280px){
            padding: mobRem(12) 0;
        }
    }

    &__text {
        font-size: rem(16);
        line-height: 125%;
        color: #FFFFFF;
        @media (max-width: 1280px){
            font-size: mobRem(16);
        }
        &:first-child {
            width: 24.3%;
            @media (max-width: 576px){
                width: auto;
            }
        }

        &.link {
            transition: .3s;
        }
    }
}

.made-by {
    display: flex;
    align-items: center;
    margin-left: auto;
    $this:&;
    @media (max-width: 576px){
        margin-left: 0;
    }
    &__text {
        font-size: rem(16);
        line-height: 150%;
        color: #FFFFFF;
        margin-right: 7px;
        transition: .3s;

        @media (max-width: 1280px){
            font-size: mobRem(16);
        }
    }
    &__svg {
        width: rem(76);
        height: rem(12);
        fill: #FFFFFF;
        transition: .3s;

        @media (max-width: 1280px){
            width: mobRem(76);
            height: mobRem(12);
        }

        &:first-of-type {
            fill: #9BBF2E;
            margin-right: rem(4);
            width: rem(16);
            height: rem(20);
            @media (max-width: 1280px){
                width: mobRem(16);
                height: mobRem(20);
            }
        }
    }


    &:hover {
        .made-by__text {
            color: #B6F84A;
        }

        .made-by__svg {
            fill: #B6F84A;
        }
    }
}
