/*	Reset
---------------------------------------*/
*  {
	margin: 0;
	padding: 0;
	box-sizing: border-box;
	font-family: inherit;
}
*:focus, *:focus *{
	box-shadow: none !important;
	outline: none !important;
}
.custom-control-input:focus~.custom-control-label::before{
	box-shadow: none !important;
}
html {
	min-height: 100%;
	height: 100%;
	&.opened{
		overflow: hidden;
		position: fixed;
	}
	
	font-size: calcFluid(10.7, 16, 1280, 1920);
	@media (max-width: 1280px) {
		font-size: 10.7px;
	}
}
body {
	min-width: 320px;
	height: 100%;
	margin: 0;
	font-family: "PTSansNarrow", sans-serif;
	&.opened {
		overflow: hidden;
		height: 100%;
	}
}

a{
	transition: color .4s;
	&:hover{
		color: $main-color-hover;
	}
}

main {
	display: flex;
	flex-direction: column;
	flex: 1 0 auto;
	overflow: hidden;
	background-position: center top;
	width: 100%;
	padding-top: rem(90);

	@media (max-width: 1199px) {
		padding-top: 50px;
	}
}

ol, ul  { 
	padding: 0;
	margin: 0;
}
li  {
	list-style-type: none;
}
.h1, .h2, .h3, .h4, h1, h2, h3, h4, p  {
	line-height: normal;
	margin: 0;
	padding: 0;
}
a:focus, button:focus, input:focus, textarea:focus  {
	outline: 0;
	text-decoration: none;
}
a  {
	text-decoration: none;
	&:hover  {
		text-decoration: none;
	}
}



// General styles
.page-wrapper {
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    position: relative;
}

.container {
	width: rem(1635);
	max-width: 100%;
    margin: 0 auto;
    padding: 0 15px;
}


.btn {
	cursor: pointer;
	position: relative;
	z-index: 1;
	border-radius: 0px rem(70) rem(70) 0px;
	background: #000000;
	color: #FFFFFF;
	font-weight: 700;
	font-size: rem(20);
	padding: 0 rem(75.5);
	height: rem(66);
	width: max-content;
	display: flex;
	align-items: center;
	text-align: center;
	justify-content: center;
	transition: all 0.3s;

	&:hover {
		background: #FFFFFF;
		color: #000000;
	}
	&--dark-hover{
		&:hover{
			background: #97D72F;
			color: #000;
		}
	}
}