@font-face {
	font-family: 'Lobster';
	src:
	url('./fonts/Lobster/Lobster-Regular.eot?#iefix') format('embedded-opentype'),
	url('./fonts/Lobster/Lobster-Regular.woff') format('woff'),
	url('./fonts/Lobster/Lobster-Regular.ttf') format('truetype');
	font-weight: normal;
	font-style: normal;
}


@font-face {
	font-family: 'Montserrat';
	src:
	url('./fonts/Montserrat/Montserrat-Regular.eot?#iefix') format('embedded-opentype'),
	url('./fonts/Montserrat/Montserrat-Regular.woff') format('woff'),
	url('./fonts/Montserrat/Montserrat-Regular.ttf') format('truetype');
	font-weight: 400;
	font-style: normal;
}


@font-face {
	font-family: 'Montserrat';
	src:
	url('./fonts/Montserrat/Montserrat-Bold.eot?#iefix') format('embedded-opentype'),
	url('./fonts/Montserrat/Montserrat-Bold.woff') format('woff'),
	url('./fonts/Montserrat/Montserrat-Bold.ttf') format('truetype');
	font-weight: 700;
	font-style: normal;
}

@font-face {
	font-family: 'PTSansNarrow';
	font-display: swap;
	src:
	url('./fonts/PTSansNarrow/PTSansNarrow-Regular.ttf') format('truetype');
	font-weight: 400;
	font-style: normal;
}

@font-face {
	font-family: 'PTSansNarrow';
	font-display: swap;
	src:
	url('./fonts/PTSansNarrow/PTSansNarrow-Bold.ttf') format('truetype');
	font-weight: 700;
	font-style: normal;
}